<template>
    <div class="container pb-5 mb-5">
        <SectionTitle title="Oportunidades de empleo" />
        <br />
        <ul class="list-group">
            <li class="list-group-item -flex bg-info" v-for="(items, tipo) in losTipos" :key="tipo">
                <div class="row">
                    <div class="col-sm-11 text-center text-white">
                        <template v-if="items.length">
                            <a
                                v-for="(item, index) in items"
                                :key="index"
                                :href="require('@/assets/eventos/empleo/' + item.imagen)"
                                :data-fancybox="`gallery${tipo}`"
                            >
                                <strong v-if="index == 0">{{ tipo }}</strong>
                            </a>
                        </template>
                        <template v-else>
                            <strong>{{ tipo }}</strong>
                        </template>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import moment from "moment";

export default {
    data() {
        return {
            tipos: {
                SUROCCIDENTE: [{ imagen: "sur052024.png", start: "2024-02-22", end: "2024-03-06" }],
                NOROCCIDENTE: [
                    { imagen: "nor052024.png", start: "2024-02-22", end: "2024-03-07" },
                    { imagen: "nor072024.png", start: "2024-02-22", end: "2024-03-07" },
                    { imagen: "nor082024.png", start: "2024-02-27", end: "2024-03-12" },
                    { imagen: "nor092024.png", start: "2024-02-27", end: "2024-03-12" },
                    { imagen: "nor0112024.png", start: "2024-03-06", end: "2024-03-20" },
                    { imagen: "nor0122024.png", start: "2024-03-06", end: "2024-03-20" },
                    { imagen: "nor0132024.png", start: "2024-03-06", end: "2024-03-20" },
                    { imagen: "nor82024.png", start: "2024-03-06", end: "2024-03-20" },
                    { imagen: "nor20032024.jpeg", start: "2024-03-20", end: "2024-04-02" },
                    { imagen: "nor0172024.png", start: "2024-04-12", end: "2024-04-26" },
                    { imagen: "nor182024.jpeg", start: "2024-04-29", end: "2024-05-10" },
                    { imagen: "nor192024.jpeg", start: "2024-04-29", end: "2024-05-10" },
                    { imagen: "nor202024.jpeg", start: "2024-04-29", end: "2024-05-10" },
                    { imagen: "nor212024.jpeg", start: "2024-04-29", end: "2024-05-10" },
                ],
                APICULTURA: [
                    { imagen: "apiSur062024.png", start: "2024-02-27", end: "2024-03-12" },
                    { imagen: "apiSur112024.png", start: "2024-02-15", end: "2024-04-26" },
                ],
                FTN: [
                    { imagen: "ftn092024.png", start: "2024-03-06", end: "2024-03-20" },
                    { imagen: "ftn102024.png", start: "2024-03-06", end: "2024-03-20" },
                    { imagen: "ftn20032024.jpeg", start: "2024-03-20", end: "2024-04-02" },
                    { imagen: "ftn122024.png", start: "2024-04-01", end: "2024-04-12" },
                    { imagen: "ftn132024.png", start: "2024-04-12", end: "2024-04-26" },
                    { imagen: "ftn142024.png", start: "2024-04-18", end: "2024-05-03" },
                ],
                CEAF: [],
                "PLANTA EL PINO": [
                    { imagen: "plantaPino032024.png", start: "2024-03-07", end: "2024-03-14" },
                    { imagen: "plantaPino102024.png", start: "2024-04-12", end: "2024-04-26" },
                    { imagen: "plantaPino112024.png", start: "2024-04-15", end: "2024-04-19" },
                    { imagen: "plantaPino112024.jpeg", start: "2024-04-29", end: "2024-05-10" },
                ],
                "PLANTA PETAPA": [],
                PETÉN: [],
                "OFICINAS CENTRALES": [
                    { imagen: "oc20032024.jpeg", start: "2024-03-20", end: "2024-04-02" },
                    { imagen: "oc220032024.jpeg", start: "2024-03-20", end: "2024-04-02" },
                    { imagen: "oc122024.png", start: "2024-04-17", end: "2024-05-03" },
                    { imagen: "oc132024.png", start: "2024-04-17", end: "2024-04-19" },
                    { imagen: "oc142024.jpeg", start: "2024-04-29", end: "2024-05-10" },
                    { imagen: "oc152024.jpeg", start: "2024-04-29", end: "2024-05-10" },
                    { imagen: "oc162024.jpeg", start: "2024-04-29", end: "2024-05-10" },
                ],
            },
            now: moment(),
        };
    },
    computed: {
        losTipos() {
            let tipos = {};
            for (let [tipo, items] of Object.entries(this.tipos)) {
                tipos[tipo] = items.filter((item) => {
                    let start = moment(item.start).startOf("day");
                    let end = moment(item.end).endOf("day");
                    return this.now.isAfter(start) && this.now.isBefore(end);
                });
            }
            console.log(tipos);

            return tipos;
        },
    },
};
</script>

<style scoped>
a {
    color: white;
}
a:hover {
    text-decoration: none;
}

.badge-info {
    background: #007bff !important;
}

.bg-info {
    background: #4e80bd !important;
}

.subsection {
    background: #c5d8f1 !important;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgb(255 255 255);
}
</style>
